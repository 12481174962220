<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <a>{{ $t("Areas") }}</a>
        <span>/</span>
        <label>
          <router-link :to="`/driver/home`">{{ $t("Home") }}</router-link>
        </label>
        <span>/</span>
        <label active>{{ $t("DetailsEvents") }}</label>
      </label>
    </div>

    <h2 class="my-5 mx-7">{{ $t("DetailsEvents") }}</h2>

    <v-card-text>
      <v-container fluid>
        <div v-loading="loading">
          <el-card v-if="adminDetails">
            <div
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'text-align:center;position:relative;top:5px;background:#f1f2a3;padding:15px 0;'
                  : 'background:#f1f2a3;padding:15px 0;margin: 15px 0;text-align:center;'
              "
            >
              <span
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'display: block;'"
                style="font-weight: bold; color: red; font-size: 22px"
                class="my-2"
              >
                {{ headerDeatils.city_name }}
              </span>
              <span
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'display: block;'"
                style="display: inline-block"
                class="my-2 mx-5"
              >
                السائق: {{ headerDeatils.driver_name }}
              </span>
              <span
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'display: block;'"
                style="display: inline-block"
                class="my-2 mx-5"
              >
                حالة الرحلة: {{ headerDeatils.trip.text }}
              </span>
              <!-- <span
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'display: block;'"
                style="display: inline-block"
                class="my-2 mx-5"
              >
                العدد: {{ headerDeatils.count }}
              </span> -->
              <span
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'display: block;'"
                style="display: inline-block"
                class="my-2 mx-5"
              >
                تم التسليم: {{ headerDeatils.delivery_count }}
              </span>
              <span
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'display: block;'"
                style="display: inline-block"
                class="my-2 mx-5"
              >
                لم يتم التسليم: {{ headerDeatils.problem_delivery_count }}
              </span>
              <span
                :style="$vuetify.breakpoint.mdAndUp ? '' : 'display: block;'"
                style="display: inline-block"
                class="my-2 mx-5"
              >
                جاري التسليم: {{ headerDeatils.not_delivery_count }}
              </span>
            </div>

            <div
              class="mt-5"
              style="
                width: 100%;
                height: 400px;
                overflow: auto;
                direction: rtl !important;
              "
            >
              <div
                style="text-align: center"
                :style="
                  $vuetify.breakpoint.mdAndUp ? 'white-space: nowrap' : ''
                "
              >
                <div
                  v-for="(ad, adKey) in adminDetails"
                  :key="adKey"
                  :style="
                    $vuetify.breakpoint.mdAndUp
                      ? 'display: inline-block;width:100%;'
                      : ''
                  "
                >
                  <table
                    class="custom-driver-table"
                    :style="
                      $vuetify.breakpoint.mdAndUp ? '' : 'margin-top:50px;'
                    "
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>الاسم</th>
                        <th>وقت التسليم</th>
                        <th>توصيل</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(ad, adkey) in ad.subscription_data"
                        :key="adkey"
                      >
                        <td>{{ parseInt(adkey) + 1 }}</td>
                        <td>
                          <a
                            @click="openDetailsModal(ad)"
                            style="
                              color: blue !important;
                              text-decoration: none;
                              cursor: pointer;
                            "
                            >{{ ad.customer_name }}</a
                          >
                        </td>
                        <td>{{ad.delivery_time}}</td>
                        <td>
                          <div v-if="ad.driver_delivery == 2">
                            <v-icon
                              class="my-0 mr-2 ml-2"
                              style="font-size: 22px; color: red !important"
                              dense
                              >mdi-close-circle-outline</v-icon
                            >
                          </div>
                          <div v-else-if="ad.driver_delivery == 1">
                            <v-icon
                              class="my-0 mr-2 ml-2"
                              style="font-size: 22px; color: green !important"
                              dense
                              >mdi-checkbox-marked-circle-outline</v-icon
                            >
                          </div>
                          <div v-else>
                            <v-icon
                              class="my-0 mr-2 ml-2"
                              style="font-size: 22px; color: purple !important"
                              dense
                              >mdi-circle-outline</v-icon
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </v-container>
    </v-card-text>

    <v-dialog v-model="delivarDetailsModal" width="80rem">
      <v-card v-if="delivarDetailsData">
        <v-toolbar style="background: blue !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            <span
              >تفاصيل تسليم العميل:
              <span>{{ delivarDetailsData.customer_name }}</span></span
            >
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <v-row class="my-2">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'">
              اسم الحي: {{ delivarDetailsData.branch_name }}
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'">
              وقت التسليم: <span>{{ delivarDetailsData.delivery_time }}</span>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'">
              طريقة التسليم: <span>{{ delivarDetailsData.notes }}</span>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'">
              سبب إلغاء التسليم:
              <span> {{ delivarDetailsData.delete_note_delivery }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "driverHome",
  data() {
    return {
      adminDetails: null,
      details: null,
      filter: {
        city_id: null,
        period_id: null,
        date: null,
      },
      cityList: [],
      periodList: [],
      loading: false,
      delivarDetailsModal: false,
      delivarDetailsData: {},
      headerDeatils: null,
    };
  },
  methods: {
    openDetailsModal(item) {
      this.delivarDetailsModal = true;
      this.delivarDetailsData = item;
    },
    getCities() {
      this.$store.dispatch(`home/listCities`).then((res) => {
        this.cityList = res.data;
      });
    },
    getPeriods() {
      this.$store.dispatch(`home/listPeriods`).then((res) => {
        this.periodList = res.data;
      });
    },
    filterAdminData() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.loading = true;
        this.adminDetails = null;
        this.adminDetails2 = null;
        let sendData;
        if (this.filter.date)
          sendData = moment(this.filter.date).format("YYYY-MM-DD");

        let send = {
          period_id: this.filter.period_id,
          date: sendData,
        };

        this.$store.dispatch(`home/getAdminDetails`, send).then((res) => {
          localStorage.setItem("home_period_id", send.period_id);
          localStorage.setItem("home_date", send.date);
          if (res.data.check) {
            this.check_approved = true;
            const adminDetail = res.data.data.find(
              (x) => x.id == parseInt(this.$route.params.id)
            );
            this.adminDetails = adminDetail ? [adminDetail] : [];
            this.headerDeatils = adminDetail;
          } else {
            this.check_approved = false;
            this.customNotifyError(
              "خطأ",
              `لم يتم اعتماد التقرير لتاريخ ${moment(this.filter.date).format(
                "YYYY-MM-DD"
              )}`
            );
          }
          this.loading = false;
        });
      });
    },
  },
  mounted() {
    // this.getCities();
    // this.getPeriods();
    if (this.userDriver) this.filter.city_id = this.userDriver.admin.city_id;
    let home_city_id = localStorage.getItem("home_city_id") || null;
    let home_date = localStorage.getItem("home_date") || null;
    let home_period_id = localStorage.getItem("home_period_id") || null;
    this.filter.date = home_date;
    if (home_city_id) this.filter.city_id = parseInt(home_city_id);
    if (home_period_id) this.filter.period_id = parseInt(home_period_id);
    this.filterAdminData();
  },
};
</script>




<style>
.custom-error .v-input__slot {
  border: 1px solid red;
}

.custom-driver-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.custom-driver-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-driver-table th,
.custom-driver-table td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
}

.custom-driver-table th {
  background-color: #3f51b5 !important;
  color: #fff;
}

.checkmark-icon,
.cross-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
}

.checkmark-icon {
  background-color: #4caf50;
  color: #fff;
}

.cross-icon {
  background-color: #f44336;
  color: #fff;
}

.area_div_block {
  background: #f1f2a3;
  padding: 15px 0;
  margin: 15px 0 0 15px;
  cursor: pointer;
}
.area_div_block:hover {
  background: #fbff00;
  color: #000;
  font-weight: bold;
}
</style>